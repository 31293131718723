<template>
  <div class="grow-count">
    <div v-for="(item,index) in content" :key="index" class="txt-box">
      <div class="title">{{ item.title }}</div>
      <div v-for="(ele,idx) in item.content" :key="idx" class="content">{{ ele }}</div>
    </div>
  </div>
</template>

<script>
import { growthValueRemind } from '@/services/goods.js'

export default {
  data() {
    return {
      content: []
    }
  },
  mounted() {
    this.getText()
  },
  methods: {
    getText() {
      growthValueRemind().then(res => {
        if (Number(res.code) === 200) {
          if (res.data) {
            res.data.content = res.data.content || []
            this.content = res.data.content
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.grow-count {
  width: 100%;
  min-height: 100vh;
  background: #F5F7FA;
  box-sizing: border-box;
  padding-top: 8px;

  .txt-box {
    margin-bottom: 8px;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px;
    background: #fff;

    .title {
      font-size: 15px;
      color: #141414;
      margin-bottom: 12px;
    }

    .content {
      text-indent: 2em;
      text-align: justify;
      font-size: 14px;
      color: #666;
    }
  }
}
</style>
